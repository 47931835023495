// Generated by Framer (0f540f8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qDyny0vBF", "s1oFVfy6q"];

const serializationHash = "framer-o2h0k"

const variantClassNames = {qDyny0vBF: "framer-v-1uhlg02", s1oFVfy6q: "framer-v-l7lqca"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "s1oFVfy6q", Open: "qDyny0vBF"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, cFhEO6EK9: click ?? props.cFhEO6EK9, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "qDyny0vBF"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cFhEO6EK9, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qDyny0vBF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapd62dr2 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (cFhEO6EK9) {const res = await cFhEO6EK9(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1uhlg02", className, classNames)} data-framer-name={"Open"} data-highlight layoutDependency={layoutDependency} layoutId={"qDyny0vBF"} onTap={onTapd62dr2} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({s1oFVfy6q: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1yn2wfb"} data-framer-name={"Rectangle 2"} layoutDependency={layoutDependency} layoutId={"uOGDhC8gw"} style={{backgroundColor: "var(--token-9e556e9b-3f7c-4df8-84cb-696985c1d319, rgb(255, 255, 255))", borderBottomLeftRadius: 3, borderBottomRightRadius: 3, borderTopLeftRadius: 3, borderTopRightRadius: 3, rotate: 0}} variants={{s1oFVfy6q: {rotate: 45}}}/><motion.div className={"framer-5c5vwh"} data-framer-name={"Rectangle 1"} layoutDependency={layoutDependency} layoutId={"hiVjtMNMZ"} style={{backgroundColor: "var(--token-9e556e9b-3f7c-4df8-84cb-696985c1d319, rgb(255, 255, 255))", borderBottomLeftRadius: 3, borderBottomRightRadius: 3, borderTopLeftRadius: 3, borderTopRightRadius: 3, rotate: 0}} variants={{s1oFVfy6q: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-o2h0k.framer-izjmzp, .framer-o2h0k .framer-izjmzp { display: block; }", ".framer-o2h0k.framer-1uhlg02 { cursor: pointer; height: 40px; overflow: visible; position: relative; width: 40px; }", ".framer-o2h0k .framer-1yn2wfb { flex: none; height: 3px; left: calc(50.00000000000002% - 26px / 2); overflow: visible; position: absolute; top: 13px; width: 26px; }", ".framer-o2h0k .framer-5c5vwh { bottom: 13px; flex: none; height: 3px; left: calc(50.00000000000002% - 26px / 2); overflow: visible; position: absolute; width: 26px; }", ".framer-o2h0k.framer-v-l7lqca.framer-1uhlg02 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 40px); }", ".framer-o2h0k.framer-v-l7lqca .framer-1yn2wfb { top: calc(50.00000000000002% - 3px / 2); }", ".framer-o2h0k.framer-v-l7lqca .framer-5c5vwh { bottom: unset; top: calc(50.00000000000002% - 3px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"s1oFVfy6q":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"cFhEO6EK9":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercaBbmuWDB: React.ComponentType<Props> = withCSS(Component, css, "framer-o2h0k") as typeof Component;
export default FramercaBbmuWDB;

FramercaBbmuWDB.displayName = "Nav / Menu Icon";

FramercaBbmuWDB.defaultProps = {height: 40, width: 40};

addPropertyControls(FramercaBbmuWDB, {variant: {options: ["qDyny0vBF", "s1oFVfy6q"], optionTitles: ["Open", "Close"], title: "Variant", type: ControlType.Enum}, cFhEO6EK9: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramercaBbmuWDB, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})